import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppPermissions } from 'src/app/permissions';
import { KeyValuePair } from '../../models/key-value-pair';
import { OperationResultWithValue } from '../../models/operation-result';
import { AeaService } from '../../services/aea/aea.service';
import { BuildingService } from '../../services/building/building.service';
import { DistrictService } from '../../services/district/district.service';
import { NotificationService } from '../../services/notification.service';

class Location {
  id: string;
  label: string;
  parentId?: string;

  constructor(id: string, label: string, parentId?: string) {
    this.id = id;
    this.label = label;
    this.parentId = parentId;
  }
}

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
})
export class LocationFilterComponent implements OnInit, OnChanges, OnDestroy {
  private sub = new Subscription();

  @Input() permissions: AppPermissions[];
  @Input() formGroup: FormGroup;
  @Input() startFrom: 'aea' | 'district' | 'building' = 'aea';
  @Input() endWith: 'aea' | 'district' | 'building' = 'building';
  @Input() childSelection: 'clear' | 'keep' = 'clear';
  @Input() isStateWide = false;

  @Input() aeaLabel = 'AEA';
  @Input() districtLabel = 'District';
  @Input() buildingLabel = 'Building';
  @Input() aeaFormControlName = 'aeaId';
  @Input() districtFormControlName = 'districtId';
  @Input() buildingFormControlName = 'buildingId';
  @Input() noneOptionAea: KeyValuePair;
  @Input() noneOptionDistrict: KeyValuePair;
  @Input() noneOptionBuilding: KeyValuePair;
  @Input() showAeaAsterisk = false;
  @Input() showDistrictAsterisk = false;
  @Input() showBuildingAsterisk = false;
  @Input() isPublicAeasOnly = false;
  @Output() changed = new EventEmitter<any>();
  @Output() loaded = new EventEmitter<any>();

  private hasAea: boolean;
  private hasDistrict: boolean;
  private hasBuilding: boolean;

  aeaOptions: KeyValuePair[];
  private aeaChildRequired: { [key: string]: boolean } = {};
  private districts: Location[];
  private districtChildRequired: { [key: string]: boolean } = {};
  districtOptions: KeyValuePair[];
  private buildings: Location[];
  buildingOptions: KeyValuePair[];

  constructor(
    private readonly aeaService: AeaService,
    private readonly districtService: DistrictService,
    private readonly buildingService: BuildingService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (!this.formGroup) this.formGroup = new FormGroup({});

    this.setLocationLevels();
    this.createForm();
    this.getData();

    this.sub.add(
      this.formGroup.valueChanges.pipe(debounceTime(100)).subscribe((v) => {
        if (this.formGroup.valid) {
          const value = {};
          if (this.hasAea) value[this.aeaFormControlName] = v[this.aeaFormControlName];
          if (this.hasDistrict) value[this.districtFormControlName] = v[this.districtFormControlName];
          if (this.hasBuilding) value[this.buildingFormControlName] = v[this.buildingFormControlName];

          this.changed.emit(value);
        }
      })
    );
  }

  private setLocationLevels() {
    this.hasAea = this.startFrom === 'aea';
    this.hasDistrict = (this.startFrom === 'aea' || this.startFrom === 'district') && this.endWith !== 'aea';
    this.hasBuilding = this.endWith === 'building';
  }

  private createForm() {
    if (this.hasAea) this.formGroup.addControl(this.aeaFormControlName, new FormControl(null));

    if (this.hasDistrict) {
      this.formGroup.addControl(this.districtFormControlName, new FormControl(null));
      if (this.hasAea) {
        this.sub.add(
          this.formGroup
            .get(this.aeaFormControlName)
            .valueChanges.pipe(debounceTime(100))
            .subscribe((v) => {
              this.aeaChanged(v);
              if (this.hasBuilding) {
                this.districtChanged(this.formGroup.get(this.districtFormControlName).value);
              }
            })
        );
      }
    }

    if (this.hasBuilding) {
      this.formGroup.addControl(this.buildingFormControlName, new FormControl(null));
      if (this.hasDistrict) {
        this.sub.add(
          this.formGroup
            .get(this.districtFormControlName)
            .valueChanges.pipe(debounceTime(100))
            .subscribe((v) => this.districtChanged(v))
        );
      }
    }
  }

  private getData() {
    if (this.hasAea) {
      this.aeaService
        .getByPermission(this.hasDistrict, this.hasBuilding, this.isStateWide, this.isPublicAeasOnly, ...(this.permissions ?? []))
        .subscribe((res) => this.setData(res));
    } else if (this.hasDistrict) {
      this.districtService
        .getByPermission(this.hasBuilding, this.isStateWide, ...(this.permissions ?? []))
        .subscribe((res) => this.setData(res));
    } else if (this.hasBuilding) {
      this.buildingService.getByPermission(...(this.permissions ?? [])).subscribe((res) => this.setData(res));
    }
  }

  private setData(res: OperationResultWithValue<any>) {
    if (this.notificationService.showResponseMessage(res)) {
      if (res.value.aeas?.length) {
        this.aeaOptions = res.value.aeas
          .map((x) => {
            this.aeaChildRequired[x.id] = !!x.childRequired;
            return new KeyValuePair(x.id, x.label);
          })
          .sort((a, b) => a.value.localeCompare(b.value));
        if (this.noneOptionAea) this.aeaOptions.unshift(this.noneOptionAea);
      }
      if (res.value.districts?.length) {
        this.districts = res.value.districts
          .map((x) => {
            this.districtChildRequired[x.id] = !!x.childRequired;
            return new Location(x.id, x.label, x.parentId);
          })
          .sort((a, b) => a.label.localeCompare(b.label));
        if (this.noneOptionDistrict) this.districts.unshift(new Location(this.noneOptionDistrict.key, this.noneOptionDistrict.value, null));
        this.aeaChanged(this.formGroup.get(this.aeaFormControlName)?.value);
      }
      if (res.value.buildings?.length) {
        this.buildings = res.value.buildings
          .map((x) => new Location(x.id, x.label, x.parentId))
          .sort((a, b) => a.label.localeCompare(b.label));
        if (this.noneOptionBuilding) this.buildings.unshift(new Location(this.noneOptionBuilding.key, this.noneOptionBuilding.value, null));
        this.districtChanged(this.formGroup.get(this.districtFormControlName)?.value);
      }
      this.loaded.emit();
    }
    this.loaded.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.childSelection) {
      if (changes.childSelection.currentValue === 'clear') this.setChildSelection = this.childSelection_Clear;
      else if (changes.childSelection.currentValue === 'keep') this.setChildSelection = this.childSelection_Keep;
      else this.setChildSelection = this.childSelection_None;
    }
  }

  private aeaChanged(aeaId: string) {
    if (!this.districts)
      // districts not available yet. Ignore remaining steps.
      return;

    const districtCtrl = this.formGroup.get(this.districtFormControlName);
    if (aeaId && this.aeaChildRequired[aeaId]) {
      districtCtrl.addValidators(Validators.required);
      districtCtrl.markAsTouched();
    } else {
      districtCtrl.clearValidators();
    }

    this.districtOptions = (!!aeaId ? this.districts.filter((x) => x.parentId === aeaId) : this.districts).map(
      (x) => new KeyValuePair(x.id, x.label)
    );
    this.setChildSelection(districtCtrl, this.districtOptions);
  }

  private districtChanged(districtId: string) {
    if (!this.buildings)
      // buildings not available yet. Ignore remaining steps.
      return;

    const buildingCtrl = this.formGroup.get(this.buildingFormControlName);
    if (districtId && this.districtChildRequired[districtId]) {
      buildingCtrl.addValidators(Validators.required);
      buildingCtrl.markAsTouched();
    } else {
      buildingCtrl.clearValidators();
    }

    let filteredBuildings: Location[];
    if (districtId) {
      filteredBuildings = this.buildings.filter((x) => x.parentId === districtId);
      if (this.noneOptionDistrict && districtId == this.noneOptionDistrict.key)
        filteredBuildings.unshift(new Location(this.noneOptionBuilding.key, this.noneOptionBuilding.value, null));
    } else if (this.formGroup.get(this.aeaFormControlName)?.value) {
      const aeaId = this.formGroup.get(this.aeaFormControlName).value;
      const filteredDistricts = this.districts.filter((x) => x.parentId === aeaId).map((x) => x.id);
      filteredBuildings = this.buildings.filter((x) => filteredDistricts.includes(x.parentId));
    } else {
      filteredBuildings = this.buildings;
    }

    this.buildingOptions = filteredBuildings.map((x) => new KeyValuePair(x.id, x.label));

    this.setChildSelection(buildingCtrl, this.buildingOptions);
  }

  private setChildSelection: (ctrl: AbstractControl, options: KeyValuePair[]) => void = this.childSelection_Clear;

  private childSelection_Clear(ctrl: AbstractControl) {
    ctrl.patchValue(null, { emitEvent: false });
  }

  private childSelection_Keep(ctrl: AbstractControl, options: KeyValuePair[]) {
    if (!options?.length || !options.some((x) => x.key === ctrl.value)) {
      ctrl.patchValue(null, { emitEvent: false });
    }
  }
  private childSelection_None(_: AbstractControl) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
