import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox/checkbox';
import { usStateOptions, yesNoOptions } from 'src/app/shared/formHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { FamilyMemberType, LearnerSummary } from 'src/app/shared/models/learner';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { FamilyRelationship } from '../../../models/family-relationship';
import dayjs from 'dayjs';
import { MatDialog } from '@angular/material/dialog';
import { AreYouSureComponent } from '../../are-you-sure-modal/are-you-sure.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-family-member',
  templateUrl: './family-member.component.html',
  styleUrls: ['./family-member.component.scss'],
})
export class FamilyMemberComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() familyMemberType: FamilyMemberType;
  @Input() formGroup: FormGroup;
  @Input() parentForm: FormGroup;
  @Input() relationships: FamilyRelationship[];
  @Input() cardTitle: string;
  @Input() isParent = false;
  @Input() referralHowHearAboutUsOptions: KeyValuePair[];
  @Input() howHearAboutUsOtherId: string;
  @Input() relationshipOptions: KeyValuePair[];
  @Input() isPk: boolean;
  @Input() isFromFamilyContact: boolean;
  @Input() isPartCLearner = false;
  @Input() learner: LearnerSummary;

  subscriptions = new Subscription();

  usStateOptions = usStateOptions;
  yesNoOptions = yesNoOptions;
  familyMemberTypes = [
    new KeyValuePair(FamilyMemberType.Parent1, 'Parent 1'),
    new KeyValuePair(FamilyMemberType.Parent2, 'Parent 2'),
    new KeyValuePair(FamilyMemberType.LivesWith1, 'Lives With 1'),
    new KeyValuePair(FamilyMemberType.LivesWith2, 'Lives With 2'),
    new KeyValuePair(FamilyMemberType.Relative, 'Relative'),
    new KeyValuePair(FamilyMemberType.Friend, 'Friend'),
    new KeyValuePair(FamilyMemberType.Guardian, 'Guardian'),
    new KeyValuePair(FamilyMemberType.Learner, 'Learner'),
  ];

  get livesWithChild() {
    return this.formGroup.get('livesWithChild')?.value;
  }

  get referralSource() {
    return this.parentForm.controls.referralSource as FormControl;
  }

  get referralSourceHowHearAboutUsId() {
    return this.parentForm.controls.referralSourceHowHearAboutUsId as FormControl;
  }

  get referralSourceEarlyAccessOtherText() {
    return this.parentForm.controls.referralSourceEarlyAccessOtherText as FormControl;
  }

  get legalGuardian() {
    return this.parentForm.controls.legalGuardian as FormControl;
  }

  get exchangeInformation() {
    return this.parentForm.controls.exchangeInformation as FormControl;
  }

  get isEdit() {
    return this.cardTitle === 'Edit';
  }

  get portalRelationship() {
    const relationshipId = this.formGroup.get('familyRelationshipId')?.value;
    return relationshipId ? this.relationships?.find((r) => r.id === relationshipId)?.allowedPortalAccess : false;
  }

  constructor(private locationService: LocationService, private dialog: MatDialog) {}

  ngAfterViewInit() {
    if (this.formGroup.controls.type?.value === FamilyMemberType.Learner) {
      this.formGroup.controls.familyRelationshipId?.disable();
    }

    if (
      this.relationshipOptions?.length > 0 &&
      this.formGroup.controls.id?.value !== null &&
      this.formGroup.controls.type?.value !== FamilyMemberType.Learner
    ) {
      setTimeout(() => {
        this.relationshipOptions = this.relationshipOptions.filter((r) => r.value !== FamilyMemberType.Learner);
      }, 100);
    }

    if (this.isFromFamilyContact) {
      this.formGroup.get('familyRelationshipId').valueChanges.subscribe((value) => {
        const relationship = this.relationships?.find((r) => r.id === value);
        if (!relationship || !relationship.allowedPortalAccess) {
          this.formGroup.get('noPortalAccess').setValue(null);
        }
      });

      const noPortalAccessControl = this.formGroup.get('noPortalAccess');
      if (noPortalAccessControl) {
        this.subscriptions.add(
          noPortalAccessControl.valueChanges.subscribe((value) => {
            if (value) {
              this.showNoPortalAccessDialog();
            }
          })
        );
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formGroup) {
      if (this.isFromFamilyContact) {
        const checkParent = (x: FamilyMemberType) => {
          this.isParent = x === FamilyMemberType.Parent1 || x === FamilyMemberType.Parent2 || x === FamilyMemberType.Parent;
        };

        checkParent(this.formGroup.controls.type.value);

        this.formGroup.controls.type.valueChanges.subscribe((x: FamilyMemberType) => {
          checkParent(x);
        });
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateReferralSource(event: MatCheckboxChange) {
    this.referralSource.markAsDirty();
    if (event.checked) {
      (this.parentForm.get('parent1') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      (this.parentForm.get('parent2') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      (this.parentForm.get('livesWith1') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      (this.parentForm.get('livesWith2') as FormGroup).get('isPersonCompletingReferral').setValue(null);
      this.formGroup.controls.isPersonCompletingReferral.setValue(true);
      this.referralSource.setValue(this.familyMemberType);
    } else {
      this.referralSource.setValue(null);
      this.formGroup.controls.isPersonCompletingReferral.setValue(null);
    }
    this.referralSourceHowHearAboutUsId.setValue(null);
  }

  populateLocationFromZipCode() {
    const zipCodeCtrl = this.formGroup.get('zipCode');
    if (zipCodeCtrl.valid && zipCodeCtrl.value) {
      this.locationService.getLocationData(zipCodeCtrl.value).subscribe((res) => {
        if (res) {
          this.formGroup.patchValue({
            city: res.city,
            county: res.county,
            state: 'IA',
          });
        }
      });
    }
  }

  relationshipAllowed(relationshipId: string) {
    if (!relationshipId) return false;
    const relationship = this.relationships?.find((x) => x.id === relationshipId);
    if (!relationship) return false;

    const isLegalGuardianOrPowerOfAttorney = ['Legal Guardian of Protected Person', 'Power of Attorney'].includes(relationship?.label);
    const isLearner = relationship?.label === 'Learner';
    const hasRightsTransferDate =
      this.learner?.iepInformation?.rightsWillNotTransfer && this.learner?.iepInformation?.dateRightsWillTransfer;
    const isAdult = dayjs(this.learner?.dateOfBirth).isBefore(dayjs().subtract(18, 'years'));

    if (isLegalGuardianOrPowerOfAttorney || isLearner) {
      if (hasRightsTransferDate) {
        return dayjs(this.learner?.iepInformation?.dateRightsWillTransfer).isBefore(dayjs());
      }
      return isAdult;
    }
    return this.relationships?.some((x) => x.id === relationshipId && x.allowedPortalAccess);
  }

  private showNoPortalAccessDialog() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        hasHtml: true,
        showCancel: true,
        showNo: false,
        subQuestion: `<span class="mt-0 mb-0">By selecting the "No Portal Access" checkbox, you are indicating the following:
        <ul class="mt-0 mb-0 pt-0 pb-0">
          <li class="mt-0 mb-0">this contact's parental rights have been legally terminated and</li>
          <li class="mt-0 mb-0">this contact should be prevented from creating an ACHIEVE Family Portal account.</li>
        </ul>
        <span class="mt-0 mb-0"><i>Selecting this checkbox will not revoke access to the ACHIEVE Family Portal if the IDEA Parent contact has already established an account. If portal access should be revoked, please contact the ACHIEVE Support Team for assistance.</i></span>

        <span class="mt-0 mb-0">Do you wish to proceed?</span>`,
      },
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.formGroup.get('noPortalAccess').setValue(false, { emitEvent: false });
      }
    });
  }
}
