import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DhhLearnerLocationUpdateDto } from '../../../../models/DhhDtos';

@Component({
  selector: 'app-update-learner-locations',
  templateUrl: './update-learner-locations.component.html',
  styleUrls: ['./update-learner-locations.component.scss'],
})
export class UpdateLearnerLocationsComponent implements OnInit {
  learnerId: string;
  formGroup = this.fb.group({});
  constructor(
    public dialogRef: MatDialogRef<UpdateLearnerLocationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DhhLearnerLocationUpdateDto,
    private fb: FormBuilder
  ) {
    this.learnerId = data.learnerId;
  }

  ngOnInit(): void {}

  loadLearnerLocations() {
    this.formGroup.patchValue({
      attendingDistrictId: this.data.attendingDistrictId,
      residentDistrictId: this.data.residentDistrictId,
    });
    this.formGroup.get('attendingDistrictId').setValidators(Validators.required);
    this.formGroup.get('residentDistrictId').setValidators(Validators.required);
    setTimeout(() => {
      this.formGroup.get('buildingId').patchValue(this.data.buildingId);
    }, 100);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onUpdate() {
    const dto = {
      learnerId: this.learnerId,
      attendingDistrictId: this.formGroup.get('attendingDistrictId').value,
      residentDistrictId: this.formGroup.get('residentDistrictId').value,
      buildingId: this.formGroup.get('buildingId').value,
    } as DhhLearnerLocationUpdateDto;
    this.dialogRef.close(dto);
  }
}
