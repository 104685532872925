import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OperationResultWithValue } from 'src/app/shared/models/operation-result';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import {
  DhhAssessmentLookupDto,
  DhhAudiologistLocationDto,
  DhhLhdLookupsDto,
  DhhLookupDto,
  DhhNoteLookupsDto,
  DhhTestLookupsDto,
} from '../models/DhhDtos';
import { NotificationService } from '../../shared/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class DhhLookupsService {
  private baseUrl = 'api/dhh/lookups';

  constructor(private readonly http: HttpClient, private readonly notificationService: NotificationService) {}

  getLocations() {
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/locations`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getRiskFactors() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/risk-factors`);
  }

  getAppointmentTypes() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/appointment-types`);
  }

  getAudiologists(districtId?: string) {
    let params = new HttpParams();
    if (districtId) {
      params = params.set('districtId', districtId);
    }

    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/audiologists`, { params });
  }

  getAudiologistsByUserPerms(userId: string) {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/audiologists-by-perms`);
  }

  getAudiometrists() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/audiometrists`);
  }

  getSoundBoothLocations() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/sound-booth-locations`);
  }

  getLearnerAppointmentTypes() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/learner-appointment-types`);
  }

  getLearnerAppointmentCancellationReason() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/learner-appointment-cancellation-reasons`);
  }

  getLearnerAppointmentRescheduleReason() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/learner-appointment-reschedule-reasons`);
  }

  getLhdLookups() {
    return this.http.get<OperationResultWithValue<DhhLhdLookupsDto>>(`${this.baseUrl}/lhd`);
  }

  getLhdAssessmentLookups() {
    return this.http.get<OperationResultWithValue<DhhAssessmentLookupDto>>(`${this.baseUrl}/lhd-assessment`);
  }

  getFrequencyLevels() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/frequency-levels`);
  }

  getDecibelLevels() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/decibel-levels`);
  }

  getNoteLookups() {
    return this.http.get<OperationResultWithValue<DhhNoteLookupsDto>>(`${this.baseUrl}/note`);
  }

  getScreeningOptions() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/screening-options`);
  }

  getTestLookups() {
    return this.http.get<OperationResultWithValue<DhhTestLookupsDto>>(`${this.baseUrl}/test`);
  }

  getAudiologistLocations() {
    return this.http.get<OperationResultWithValue<DhhAudiologistLocationDto>>(`${this.baseUrl}/audiologist-locations`);
  }

  getTestContacts(aeaId: string) {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/contacts?aeaId=${aeaId}`);
  }

  getContactAddresses(aeaId: string) {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/addresses?aeaId=${aeaId}`);
  }

  getDocumentCategories() {
    return this.http.get<OperationResultWithValue<DhhLookupDto[]>>(`${this.baseUrl}/document-categories`);
  }

  private throwError(errorTitle: string, error: any): Observable<string> {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = Array.isArray(error.error.errors)
        ? error.error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.error.errors)
            .map((key) => error.error.errors[key])
            ?.join(',');
    } else if (error && error.errors) {
      errorMessage = Array.isArray(error.errors)
        ? error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.errors)
            .map((key) => error.errors[key])
            ?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    return throwError({ title: errorTitle, message: new Error(errorMessage) });
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = Array.isArray(error.error.errors)
        ? error.error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.error.errors)
            .map((key) => error.error.errors[key])
            ?.join(',');
    } else if (error && error.errors) {
      errorMessage = Array.isArray(error.errors)
        ? error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.errors)
            .map((key) => error.errors[key])
            ?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }
}
