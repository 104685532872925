import { EnumDictionary } from 'src/app/shared/typeHelpers';
import { KeyValuePair } from './../key-value-pair';
import { ConsentStatus } from './consent-status';
import { FamilyConsentStatus } from './family-consent-status';

export interface ConsentFormAgency {
  name: string;
  contact: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipCode: number;
  fax?: string;
}

export interface ConsentForm {
  id?: string;
  caseId: string;
  referralDocumentId?: string;
  learnerId?: string;
  statuses: ConsentStatus[];
  lastStatus?: ConsentStatus;
  type?: ConsentFormType;
  updatedOn?: Date;
  ifspId?: string;
  reevaluationConsentId?: string;
  reevaluationFacilitatorId?: string;
  iepId?: string;
  createdOn: Date;
  parentsProvidedFiieConsentDate?: Date;
  aeaId?: string;
  districtId?: string;
  aeaOrDistrictName?: string;
  agency?: ConsentFormAgency;
  signedFormId?: string;
  isComplete: boolean;
  additionalData?: ConsentFormAdditionalData;
}

export interface ConsentFormAdditionalData {
  meetingId?: string;
  participantIds?: string[];
}

export enum ConsentFormType {
  InitialFamilyEvaluationAndAssessment = 'InitialFamilyEvaluationAndAssessment',
  PostReferralScreening = 'PostReferralScreening',
  ReleaseAndExchangeInformation = 'ReleaseAndExchangeInformation',
  AuthorizationReleaseAndExchangeInformation = 'AuthorizationReleaseAndExchangeInformation',
  AgreementToExcuseIep = 'AgreementToExcuseIep',
  FiieConsent = 'FiieConsent',
  InitialIepServices = 'InitialIepServices',
  ReceiveElectronicCommunication = 'ReceiveElectronicCommunication',
  Reevaluation = 'Reevaluation',
  EarlyAccessServices = 'EarlyAccessServices',
  MedicaidAEA = 'MedicaidAEA',
  MedicaidLEA = 'MedicaidLEA',
  ConsentFIE = 'ConsentFIE',
  ParentGuardianMedicaidReimbursement = 'ParentGuardianMedicaidReimbursement',
  IvrsConsent = 'IvrsConsent',
  HomeschoolingSpecialEd = 'HomeschoolingSpecialEd',
  MediationAgreementToMediate = 'MediationAgreementToMediate',
  MediationLegallyBindingAgreement = 'MediationLegallyBindingAgreement',
}

export const consentFormTitlesByType: EnumDictionary<ConsentFormType, string> = {
  [ConsentFormType.FiieConsent]: 'Consent for Full and Individual Initial Evaluation',
  [ConsentFormType.PostReferralScreening]: 'Consent for Post-Referral Screening',
  [ConsentFormType.ReleaseAndExchangeInformation]: 'Consent to Release and Exchange Information',
  [ConsentFormType.AuthorizationReleaseAndExchangeInformation]: 'Authorization to Release and Exchange Information',
  [ConsentFormType.InitialFamilyEvaluationAndAssessment]: 'Consent for Early ACCESS Evaluation and Assessment',
  [ConsentFormType.AgreementToExcuseIep]: 'Agreement to Excuse Attendance at IEP Meeting',
  [ConsentFormType.InitialIepServices]: 'Consent for Special Education and Related Services',
  [ConsentFormType.ReceiveElectronicCommunication]: 'Consent to Receive Electronic Communication',
  [ConsentFormType.Reevaluation]: 'Consent for Reevaluation',
  [ConsentFormType.EarlyAccessServices]: 'Consent for Early ACCESS Services',
  [ConsentFormType.MedicaidAEA]: 'Consent For Medicaid AEA',
  [ConsentFormType.MedicaidLEA]: 'Consent For Medicaid LEA',
  [ConsentFormType.ConsentFIE]: 'Consent For Full and Individual Evaluation',
  [ConsentFormType.ParentGuardianMedicaidReimbursement]: 'Consent for Medicaid Reimbursement for IFSP Services',
  [ConsentFormType.IvrsConsent]: 'Consent to Release Information to and from Iowa Vocational Rehabilitation Services',
  [ConsentFormType.HomeschoolingSpecialEd]: 'Consent for Homeschooling',
  [ConsentFormType.MediationAgreementToMediate]: 'N/A',
  [ConsentFormType.MediationLegallyBindingAgreement]: 'N/A',
};

export const familyConsentStatusOptions: KeyValuePair[] = [
  new KeyValuePair(FamilyConsentStatus.Approved, 'Approved'),
  new KeyValuePair(FamilyConsentStatus.Denied, 'Denied'),
  new KeyValuePair(FamilyConsentStatus.Revoked, 'Revoked'),
  new KeyValuePair(FamilyConsentStatus.Requested, 'Requested'),
  new KeyValuePair(FamilyConsentStatus.Editing, 'Editing'),
  new KeyValuePair(FamilyConsentStatus.Pending, 'Pending'),
  new KeyValuePair(FamilyConsentStatus.Override, 'Override'),
];
