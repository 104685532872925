import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { OperationResult, OperationResultWithValue } from 'src/app/shared/models/operation-result';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SearchParams } from '../../child-find/search/search';
import { PaginatedList } from '../../shared/models/paginated-list';
import { DateFormatNoConversionPipe } from '../../shared/pipes/date-transform.pipe';
import {
  DhhAddLearnerFromSifDto,
  DhhAppointmentDto,
  DhhCaseList,
  DhhEventDto,
  DhhExitDto,
  DhhExitLookup,
  DhhLearnerAppointmentDto,
  DhhLearnerAppointmentLetterDto,
  DhhLearnerDto,
  DhhLearnerSearchResultDto,
  DhhLearnerWithScreeningResultDto,
  DhhPendingAppointmentDto,
  DhhType,
} from '../models/DhhDtos';

@Injectable({
  providedIn: 'root',
})
export class DhhService {
  private dhhBaseUrl = 'api/dhh';

  constructor(private readonly http: HttpClient, private readonly notificationService: NotificationService) {}

  search(searchParams: SearchParams, pageNumber: number, pageSize: number, orderBy = 'fullName', orderByDescending = false) {
    let params = new HttpParams();

    Object.keys(searchParams).forEach((k) => {
      params = params.set(k, searchParams[k]);
    });

    params = params.set('pageNumber', pageNumber);
    params = params.set('pageSize', pageSize);
    params = params.set('orderByDescending', orderByDescending);
    params = params.set('orderBy', orderBy);

    return this.http.get<OperationResultWithValue<PaginatedList<DhhLearnerSearchResultDto>>>(`${this.dhhBaseUrl}/learners`, { params });
  }

  pendingLearners() {
    return this.http.get<OperationResultWithValue<DhhLearnerSearchResultDto[]>>(`${this.dhhBaseUrl}/pending-learners`);
  }

  getLearner(learnerId: string) {
    return this.http.get<OperationResultWithValue<DhhLearnerWithScreeningResultDto>>(`${this.dhhBaseUrl}/learner?learnerId=${learnerId}`);
  }

  searchDhhCase(
    dhhType: DhhType,
    currentPage: number,
    pageSize: number,
    orderByDescending: boolean,
    orderBy: string,
    searchCriteria?: string,
    searchCriteriaDistrict?: string,
    searchCriteriaBuilding?: string
  ) {
    let params = new HttpParams();
    params = params.set('dhhType', dhhType);
    params = params.set('pageNumber', currentPage);
    params = params.set('pageSize', pageSize);
    params = params.set('orderByDescending', orderByDescending);
    params = params.set('orderBy', orderBy);
    params = params.set('searchCriteria', searchCriteria ?? '');
    params = params.set('searchCriteriaDistrict', searchCriteriaDistrict ?? '');
    params = params.set('searchCriteriaBuilding', searchCriteriaBuilding ?? '');

    return this.http.get<OperationResultWithValue<DhhCaseList>>(`${this.dhhBaseUrl}/cases`, { params });
  }

  addLearner(learner: DhhLearnerDto) {
    return this.http.post<OperationResultWithValue<string>>(`${this.dhhBaseUrl}/add-learner`, learner);
  }

  addLearnerFromSif(learner: DhhAddLearnerFromSifDto) {
    return this.http.post<OperationResultWithValue<string>>(`${this.dhhBaseUrl}/add-sif-learner`, learner);
  }

  updateLearner(learner: DhhLearnerDto) {
    return this.http.post<OperationResultWithValue<string>>(`${this.dhhBaseUrl}/update-learner`, learner);
  }

  deleteLearner(learnerId: string) {
    return this.http.post<OperationResult>(`${this.dhhBaseUrl}/delete-learner?learnerId=${learnerId}`, {});
  }

  addAppointments(appointments: DhhAppointmentDto[]) {
    return this.http.post<OperationResult>(`${this.dhhBaseUrl}/add-appointments`, appointments);
  }

  getAppointment(appointmentId: string) {
    return this.http.get<OperationResultWithValue<DhhAppointmentDto>>(`${this.dhhBaseUrl}/appointment?appointmentId=${appointmentId}`);
  }

  updateAppointment(appointment: DhhAppointmentDto) {
    return this.http.post<OperationResult>(`${this.dhhBaseUrl}/update-appointment`, appointment);
  }

  cancelAppointment(appointmentId: string) {
    return this.http.post<OperationResult>(`${this.dhhBaseUrl}/cancel-appointment?appointmentId=${appointmentId}`, {});
  }

  getLearnerAppointments(dhhAppointmentId: string, learnerId?: string) {
    return this.http.get<OperationResultWithValue<DhhLearnerAppointmentDto[]>>(
      `${this.dhhBaseUrl}/learner-appointments?dhhAppointmentId=${dhhAppointmentId}`,
      {
        params: { learnerId: learnerId !== null ? learnerId : '' },
      }
    );
  }

  addLearnerAppointment(appointment: DhhLearnerAppointmentDto) {
    return this.http.post<OperationResultWithValue<DhhLearnerAppointmentDto>>(`${this.dhhBaseUrl}/add-learner-appointment`, appointment);
  }

  getLearnerAppointment(learnerAppointmentId: string) {
    return this.http.get<OperationResultWithValue<DhhLearnerAppointmentDto>>(
      `${this.dhhBaseUrl}/learner-appointment?learnerAppointmentId=${learnerAppointmentId}`
    );
  }

  getUpcomingLearnerAppointments(learnerId: string) {
    return this.http.get<OperationResultWithValue<DhhLearnerAppointmentDto[]>>(
      `${this.dhhBaseUrl}/learner-upcoming-appointments?learnerId=${learnerId}`
    );
  }

  getPastLearnerAppointments(learnerId: string) {
    return this.http.get<OperationResultWithValue<DhhLearnerAppointmentDto[]>>(
      `${this.dhhBaseUrl}/learner-past-appointments?learnerId=${learnerId}`
    );
  }

  cancelLearnerAppointment(learnerAppointmentId: string, cancellationReasonId: string) {
    return this.http.post<OperationResult>(
      `${this.dhhBaseUrl}/cancel-learner-appointment?learnerAppointmentId=${learnerAppointmentId}&cancellationReasonId=${cancellationReasonId}`,
      {}
    );
  }

  rescheduleLearnerAppointment(appointment: DhhLearnerAppointmentDto) {
    return this.http.post<OperationResultWithValue<string>>(`${this.dhhBaseUrl}/reschedule-learner-appointment`, appointment);
  }

  keptLearnerAppointment(learnerAppointmentId: string) {
    return this.http.post<OperationResult>(`${this.dhhBaseUrl}/kept-learner-appointment?learnerAppointmentId=${learnerAppointmentId}`, {});
  }

  reassignAudiologist(learnerId: string, audiologistUserId: string) {
    return this.http.post<OperationResult>(
      `${this.dhhBaseUrl}/reassign-audiologist?learnerId=${learnerId}&audiologistUserId=${audiologistUserId}`,
      {}
    );
  }

  getPendingAppointments(
    searchCriteriaName: string,
    searchCriteriaAudiologist?: string,
    searchCriteriaSoundBooth?: string,
    searchCriteriaDistrict?: string,
    searchCriteriaLearner?: string,
    searchCriteriafromDate?: Date,
    searchCriteriatoDate?: Date,
    searchCriteriaAppointmentType?: string
  ) {
    let params = new HttpParams();
    params = params.set('searchCriteriaName', searchCriteriaName ?? '');
    params = params.set('searchCriteriaAudiologist', searchCriteriaAudiologist ?? '');
    params = params.set('searchCriteriaSoundBooth', searchCriteriaSoundBooth ?? '');
    params = params.set('searchCriteriaDistrict', searchCriteriaDistrict ?? '');
    params = params.set('searchCriteriaLearner', searchCriteriaLearner ?? '');
    if (searchCriteriafromDate) {
      const fromDate = new DateFormatNoConversionPipe().transform(searchCriteriafromDate, 'short');
      params = params.set('fromDate', fromDate);
    }
    if (searchCriteriatoDate) {
      const toDate = new DateFormatNoConversionPipe().transform(searchCriteriatoDate, 'short');
      params = params.set('toDate', toDate);
    }
    params = params.set('searchCriteriaAppointmentType', searchCriteriaAppointmentType ?? '');

    return this.http.get<OperationResultWithValue<DhhPendingAppointmentDto[]>>(`${this.dhhBaseUrl}/pending-appointments`, { params });
  }

  getPendingAppointmentsByLearnerId(learnerId: string) {
    return this.getPendingAppointments(null, null, null, null, learnerId, null, null, null);
  }

  getPendingAppointmentsConfirmationLetters(learnerIds: string[]) {
    return this.http.post<OperationResultWithValue<DhhLearnerAppointmentLetterDto[]>>(
      `${this.dhhBaseUrl}/pending-appointments-confirmation-letters`,
      learnerIds
    );
  }

  getEvents(soundBoothOnly = false) {
    return this.http.get<OperationResultWithValue<DhhEventDto[]>>(`${this.dhhBaseUrl}/events?soundBoothOnly=${soundBoothOnly}`);
  }

  getExitReasonsLookup(learnerId: string) {
    return this.http.get<OperationResultWithValue<DhhExitLookup>>(`${this.dhhBaseUrl}/lhd/exit-reasons-lookup?learnerId=${learnerId}`);
  }

  dhhLearnerInactivate(dto: DhhExitDto) {
    return this.http.post<OperationResultWithValue<boolean>>(`${this.dhhBaseUrl}/lhd/dhh-learner-inactivate`, dto);
  }

  dhhLearnerReactivate(learnerId: string) {
    return this.http.post<OperationResultWithValue<boolean>>(`${this.dhhBaseUrl}/lhd/dhh-learner-reactivate?learnerId=${learnerId}`, {});
  }

  getLearnerConsentAgencies(learnerId: string) {
    return this.http.get<OperationResultWithValue<any>>(`${this.dhhBaseUrl}/lhd/dhh-learner-consent-agencies?learnerId=${learnerId}`);
  }

  searchDocuments(searchParams: any, pageNumber: number, pageSize: number, orderBy = 'createdon', orderByDescending = true) {
    let params = new HttpParams();

    Object.keys(searchParams).forEach((k) => {
      params = params.set(k, searchParams[k]);
    });

    params = params.set('pageNumber', pageNumber);
    params = params.set('pageSize', pageSize);
    params = params.set('orderByDescending', orderByDescending);
    params = params.set('orderBy', orderBy);

    return this.http.get<OperationResultWithValue<PaginatedList<any>>>(`${this.dhhBaseUrl}/documents`, { params });
  }

  uploadDocumentation(learnerId: string, model: any) {
    return this.http.post<OperationResultWithValue<any>>(`${this.dhhBaseUrl}/documents?learnerId=${learnerId}`, model);
  }

  canAccessDhhLearner(learnerId: string) {
    return this.http.get<OperationResultWithValue<boolean>>(`${this.dhhBaseUrl}/can-access-dhh-learner?learnerId=${learnerId}`);
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = Array.isArray(error.error.errors)
        ? error.error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.error.errors)
            .map((key) => error.error.errors[key])
            ?.join(',');
    } else if (error && error.errors) {
      errorMessage = Array.isArray(error.errors)
        ? error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.errors)
            .map((key) => error.errors[key])
            ?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }

  getCurrentYearStartDate(): Date {
    const currentDate = dayjs();
    const currentYear = currentDate.year();
    const previousYear = currentYear - 1;
    const startDate =
      currentDate.isAfter(`${previousYear}-06-30`) && currentDate.isBefore(`${currentYear}-06-30`)
        ? dayjs(`${previousYear}-07-01`)
        : dayjs(`${currentYear}-07-01`);

    return startDate.toDate();
  }
}
