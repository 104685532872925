import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { noNumbersValidator } from 'src/app/shared/validators';
import { AuthService } from '../../../auth/auth.service';
import { SearchDataSource, SearchParams } from '../../../child-find/search/search';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { PaginatedList } from '../../../shared/models/paginated-list';
import { MemoryStorageService } from '../../../shared/services/memory-storage/memory-storage.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { DhhLearnerSearchResultDto } from '../../models/DhhDtos';
import { DhhLookupsService } from '../../services/dhh-lookups.service';
import { DhhRoutingService } from '../../services/dhh-routing.service';
import { DhhService } from '../../services/dhh.service';

const stateAssignedIdLengthValidator = (control: FormControl): ValidationErrors | null => {
  return control.value && control.value.toString().length !== 10 ? { stateIdInvalidLength: true } : null;
};

const constrainedSearch = (control: FormGroup): ValidationErrors | null => {
  const firstName = control.get('firstName').value;
  const lastName = control.get('lastName').value;
  const dobControl = control.get('dateOfBirth');
  const stateId = control.get('stateAssignedId').value;

  return firstName || lastName || (dobControl.valid && dobControl.value) || stateId ? null : { searchInputRequired: true };
};

@Component({
  selector: 'app-dhh-child-search',
  templateUrl: './dhh-child-search.component.html',
  styleUrls: ['./dhh-child-search.component.scss'],
})
export class DhhChildSearchComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  districts: any[] = [];

  aeaOptions: KeyValuePair[];
  districtOptions: KeyValuePair[];
  isReady = false;
  today = dayjs().startOf('day').toDate();
  twentyFiveYearsAgo = dayjs(this.today).subtract(25, 'year').toDate();
  formGroup: FormGroup;
  searchParams: SearchParams;
  learners: PaginatedList<DhhLearnerSearchResultDto>;
  pageNumber = 0;
  pageSize = 20;
  orderBy = 'fullName';
  orderByDescending = false;
  submitAttempted = false;
  dataSourceOptions: KeyValuePair[] = [
    new KeyValuePair(SearchDataSource.AllLearners, 'All Learners'),
    new KeyValuePair(SearchDataSource.ACHIEVELearnersOnly, 'ACHIEVE / DHH Learners Only'),
  ];

  get canAddNewLearner() {
    return this.authService.isDhhAudiologist || this.authService.isDhhScheduler;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhService: DhhService,
    private readonly authService: AuthService,
    private readonly spinnerService: SpinnerService,
    private readonly memoryStorageService: MemoryStorageService,
    private readonly dhhRoutingService: DhhRoutingService
  ) {}

  ngOnInit(): void {
    this.initializeFormGroup();

    setTimeout(() => this.spinnerService.incrementLoading());

    this.getLocation().finally(() => {
      this.spinnerService.decrementLoading();
      this.filterDistricts();
      this.isReady = true;
    });

    this.subscription.add(
      this.formGroup.get('attendingAeaId').valueChanges.subscribe((aeaId) => {
        this.formGroup.get('attendingDistrictId').reset();
        this.filterDistricts(aeaId);
      })
    );
  }

  initializeFormGroup() {
    this.formGroup = this.fb.group({
      lastName: ['', [noNumbersValidator]],
      firstName: ['', [noNumbersValidator]],
      dateOfBirth: ['', { updateOn: 'blur' }],
      stateAssignedId: [null, [stateAssignedIdLengthValidator]],
      attendingDistrictId: [''],
      attendingAeaId: [''],
      searchDataSource: [SearchDataSource.AllLearners],
    });

    setTimeout(() => {
      this.formGroup.setValidators([constrainedSearch]);
      this.formGroup.updateValueAndValidity();
    }, 100);
  }

  clear() {
    this.districtOptions = this.getHierarchicalLookup(this.districts);
    this.formGroup.reset();
    this.learners = {} as PaginatedList<DhhLearnerSearchResultDto>;
    this.submitAttempted = false;
  }

  getLocation() {
    const removeChildren = (lookup) => {
      return {
        childRequired: lookup.childRequired,
        id: lookup.id,
        isDeleted: lookup.isDeleted,
        label: lookup.label,
        lookupName: lookup.lookupName,
        parentId: lookup.parentId,
      };
    };

    return new Promise<void>((resolve, reject) => {
      this.dhhLookupService.getLocations().subscribe({
        next: (locations) => {
          const aeas = locations ?? [];
          const districts = aeas.flatMap((x) => x.children);

          const aeasLookup = aeas.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];
          this.districts = districts.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];

          this.aeaOptions = this.getHierarchicalLookup(aeasLookup);
          this.districtOptions = this.getHierarchicalLookup(this.districts);
        },
        complete: () => resolve(),
        error: (err) => this.dhhService.handleError('Error retrieving locations.', err.message),
      });
    });
  }

  private getHierarchicalLookup(lookup, parentId?) {
    return lookup.filter((x) => (parentId && x.parentId === parentId) || !parentId).map((x) => new KeyValuePair(x.id, x.label));
  }

  filterDistricts(aeaId?: string) {
    if (aeaId) {
      this.districtOptions = this.getHierarchicalLookup(this.districts, aeaId);
    } else {
      this.districtOptions = this.getHierarchicalLookup(this.districts);
    }
  }

  onPaginationChange(event) {
    this.pageNumber = event.pageNumber;
    this.pageSize = event.pageSize;
    this.orderBy = event.orderBy;
    this.orderByDescending = event.orderByDescending;
    this.search();
  }

  onSearch() {
    if (!this.formGroup.valid) {
      this.dhhService.handleError('Invalid Search input', { message: 'Please provide all required fields.' });
      return;
    }

    this.setSearchParameters();

    this.learners = {} as PaginatedList<DhhLearnerSearchResultDto>;
    this.search();
  }

  search() {
    setTimeout(() => this.spinnerService.incrementLoading());

    this.dhhService.search(this.searchParams, this.pageNumber, this.pageSize, this.orderBy, this.orderByDescending).subscribe(
      (result) => {
        if (result.succeeded) {
          this.learners = result.value;
        } else {
          this.dhhService.handleError('Search failed', result.errors);
        }
        setTimeout(() => this.spinnerService.decrementLoading());
        this.submitAttempted = true;
      },
      (error) => {
        this.dhhService.handleError('Search failed', error);
        setTimeout(() => this.spinnerService.decrementLoading());
      }
    );
  }

  private setSearchParameters() {
    const formValues = this.formGroup.value;
    const keys = Object.keys(formValues);
    this.searchParams = keys.reduce((accum, k) => {
      if (!!formValues[k]) {
        if (formValues[k] instanceof Date) {
          accum[k] = formatDate(formValues[k], 'yyyy-MM-ddT00:00:00.000-0000', 'en-US');
        } else {
          accum[k] = formValues[k].toString().trim();
        }
      }
      return accum;
    }, {} as SearchParams);
  }

  onAddNewLearner() {
    const learnerFormValue = this.formGroup.value;
    learnerFormValue.stateAssignedId =
      this.authService.isAchieveDataLead || this.authService.isDataTechnician || this.authService.isSuperAdmin
        ? learnerFormValue.stateAssignedId
        : '';
    this.memoryStorageService.setKey('dhh-learner-entry', learnerFormValue);
    this.dhhRoutingService.goToLearnerEntry();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
