<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    *ngIf="!isPopup && !isInteractiveAccess"
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar class="sidenav__brand">
      <img src="../../assets/img/achieve-logo.svg" alt="ACHIEVE Logo" class="sidenav__logo" />
    </mat-toolbar>

    <mat-nav-list>
      <div class="contain-list" *ngIf="!isVr && (isLearner || isFamily) && !isEligibleFamily">
        <a mat-list-item [routerLink]="['/', 'portal']" tabindex="0" role="link" aria-label="My Dashboard">My Dashboard</a>
        <a mat-list-item [routerLink]="['/', 'portal', 'resources']" tabindex="0" role="link" aria-label="Resources"> Resources </a>

        <div class="bottom-buttons">
          <button mat-raised-button aria-label="Click to View Help Information" (click)="onOpenHelp()" tabindex="0">Help</button>
        </div>

        <span class="version"
          ><small>v{{ version }}</small></span
        >
      </div>

      <div class="contain-list" *ngIf="!isVr && isAea">
        <a mat-list-item *ngIf="canViewDashboard" [routerLink]="['/']" tabindex="0" role="link" aria-labelledby="My Dashboard"
          >My Dashboard</a
        >
        <mat-accordion
          *ngIf="isDhhFeatureFlagOn && (isDhh || authService.isAchieveDataLead || authService.isDataTechnician || canViewDhhReporting)"
        >
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="position-relative">
              <mat-panel-title class="display-flex align-items-center">
                <span
                  [routerLink]="['/', 'dhh']"
                  routerLinkActive="route__indicator--active"
                  [routerLinkActiveOptions]="{ exact: false }"
                ></span>
                DHH
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a
                mat-list-item
                [routerLink]="['/', 'dhh', 'child-search']"
                tabindex="0"
                role="link"
                aria-label="DHH Child Search"
                *ngIf="isDhh || authService.isAchieveDataLead || authService.isDataTechnician"
                >DHH Child Search</a
              >
              <a
                *ngIf="isDhh"
                mat-list-item
                [routerLink]="['/', 'dhh', 'list-search']"
                tabindex="0"
                role="link"
                aria-label="DHH List Search"
                >DHH List Search</a
              >
              <a
                *ngIf="canViewDhhScreening"
                mat-list-item
                [routerLink]="['/', 'dhh', 'screening']"
                tabindex="0"
                role="link"
                aria-label="Screening"
                >Screening</a
              >
              <a
                *ngIf="canViewDhhRecheck"
                mat-list-item
                [routerLink]="['/', 'dhh', 'recheck']"
                tabindex="0"
                role="link"
                aria-label="Recheck"
                >Recheck</a
              >
              <a
                mat-list-item
                [routerLink]="['/', 'dhh', 'pending-learners']"
                tabindex="0"
                role="link"
                aria-label="Pending Learners"
                *ngIf="isDhh || authService.isAchieveDataLead || authService.isDataTechnician"
                >Pending Learners</a
              >
              <a
                *ngIf="canViewDhhReporting"
                mat-list-item
                [routerLink]="['/', 'canned-report', 'adhoc']"
                tabindex="0"
                role="link"
                aria-label="DHH Reporting"
                >DHH Reporting</a
              >
              <a
                *ngIf="isDhh"
                mat-list-item
                [routerLink]="['/', 'dhh', 'administration']"
                tabindex="0"
                role="link"
                aria-label="DHH Administration"
                >DHH Administration</a
              >
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel
            class="mat-elevation-z0"
            *ngIf="!isReportingOnly && !onlyLeaUserManagerRoleAssigned && !isGeneralEducation && !isDhhOnly"
          >
            <mat-expansion-panel-header class="position-relative">
              <mat-panel-title class="display-flex align-items-center">
                <span
                  [routerLink]="['/', 'child-find']"
                  routerLinkActive="route__indicator--active"
                  [routerLinkActiveOptions]="{ exact: false }"
                ></span>
                Child Find
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a
                mat-list-item
                [routerLink]="['/', 'child-find', 'early-access-referral']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="canViewReferrals"
                tabindex="0"
                role="link"
              >
                Referral
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'child-find', 'early-access-referral', 'referral-list']"
                routerLinkActive="route--active"
                *ngIf="canViewPendingReferrals"
              >
                Pending Referrals
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'child-find', 'search']"
                routerLinkActive="route--active"
                tabindex="0"
                role="link"
                aria-labelledby="Child Search"
              >
                Child Search
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'child-find', 'location-search']"
                routerLinkActive="route--active"
                tabindex="0"
                role="link"
              >
                Location Search
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'child-find', 'service-coordinator-assignment']"
                routerLinkActive="route--active"
                *ngIf="canAssignServiceCoordinator"
                tabindex="0"
                role="link"
              >
                Service Coordinator Assignment
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- <mat-expansion-panel class="mat-elevation-z0"  >
        <mat-expansion-panel-header class="position-relative">
          <mat-panel-title class="display-flex align-items-center">
            <span
              [routerLink]="['/', 'medicaid']"
              routerLinkActive="route__indicator--active"
              [routerLinkActiveOptions]="{ exact: false }"
              tabindex="0"
              role="link"
            ></span>
            Medicaid
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
          <a
            mat-list-item
            (click)="openMedicaidReport()"
            href="javascript:void(0)"
          >
            Reports
          </a>
        </mat-nav-list>
      </mat-expansion-panel> -->

          <a
            mat-list-item
            [routerLink]="['/', 'canned-report', 'reports']"
            tabindex="0"
            role="link"
            aria-labelledby="Reporting"
            *ngIf="!isLeaUserManager && !isGeneralEducation && !isDhhOnly"
          >
            Reporting
          </a>

          <mat-expansion-panel
            class="mat-elevation-z0"
            *ngIf="
              authService.isSuperAdmin ||
              authService.isAchieveDataLead ||
              (authService.isDataTechnician && authService.canAssignPermissions) ||
              authService.isLeaUserManager ||
              authService.isCountDashboardAdmin
            "
          >
            <mat-expansion-panel-header class="position-relative">
              <mat-panel-title class="display-flex align-items-center">
                <span
                  [routerLink]="['/', 'admin']"
                  routerLinkActive="route__indicator--active"
                  [routerLinkActiveOptions]="{ exact: false }"
                ></span>
                Administration
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a
                mat-list-item
                [routerLink]="['/', 'users', 'list']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="
                  authService.isSuperAdmin ||
                  ((authService.isAchieveDataLead || authService.isDataTechnician) && authService.canAssignPermissions) ||
                  authService.isLeaUserManager
                "
                tabindex="0"
                role="link"
              >
                User Management
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'admin', 'data-management', 'referral-source-categories']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="authService.isSuperAdmin"
                tabindex="0"
                role="link"
              >
                Content Management
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'admin', 'building']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="authService.isSuperAdmin || authService.isAchieveDataLead"
                tabindex="0"
                role="link"
              >
                Building Management
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'admin', 'services-and-activities']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="authService.isSuperAdmin"
                tabindex="0"
                role="link"
              >
                Services Management
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'admin', 'pending-destruction-list']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="authService.isSuperAdmin || authService.isAchieveDataLead || authService.isDataTechnician"
                tabindex="0"
                role="link"
              >
                Pending Destruction of Records List
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'survey-tool', 'admin']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="authService.isSuperAdmin"
                tabindex="0"
                role="link"
              >
                Survey Manager
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'admin', 'sdi-management']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="authService.isSuperAdmin"
                tabindex="0"
                role="link"
              >
                SDI Management
              </a>
              <a
                mat-list-item
                [routerLink]="['/', 'admin', 'count-admin']"
                routerLinkActive="route--active"
                *ngIf="authService.isSuperAdmin || authService.isCountDashboardAdmin"
                tabindex="0"
                role="link"
              >
                Count
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="authService.isUberAdmin" class="mat-elevation-z0">
            <mat-expansion-panel-header class="position-relative">
              <mat-panel-title class="display-flex align-items-center">
                <span
                  [routerLinkActiveOptions]="{ exact: false }"
                  [routerLink]="['/', 'test']"
                  routerLinkActive="route__indicator--active"
                ></span>
                Demo/Test Stuff
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a [routerLink]="['/', 'test', 'canvas']" mat-list-item tabindex="0" role="link" aria-labelledby="Canvas Signature">
                Canvas Signature
              </a>
            </mat-nav-list>
            <mat-nav-list dense>
              <a [routerLink]="['/', 'test', 'wrappers']" mat-list-item tabindex="0" role="link" aria-labelledby="Form Wrappers">
                Form Wrappers
              </a>
            </mat-nav-list>
            <mat-nav-list dense>
              <a [routerLink]="['/', 'test', 'aggrid']" mat-list-item tabindex="0" role="link" aria-labelledby="Ag-Grid"> Ag-Grid </a>
            </mat-nav-list>
            <mat-nav-list dense>
              <a [routerLink]="['/', 'test', 'mattable']" mat-list-item tabindex="0" role="link" aria-labelledby="Mat Table"> Mat Table </a>
            </mat-nav-list>
            <mat-nav-list dense>
              <a
                mat-list-item
                [routerLink]="['/', 'admin', 'cause-error']"
                routerLinkActive="route--active"
                [routerLinkActiveOptions]="{ exact: true }"
                tabindex="0"
                role="link"
              >
                Cause Errors
              </a>
            </mat-nav-list>
            <mat-nav-list dense>
              <button mat-raised-button aria-label="New E-Consent" (click)="onOpenEconsent()" tabindex="0">New E-Consent</button>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>

        <a
          mat-list-item
          [routerLink]="['/', 'monitoring-progress', 'grade-book']"
          *appAuthForRoles="{ permission: [permissions.ViewMultiProgressMonitoring], caseId: undefined }"
          tabindex="0"
          role="link"
        >
          Progress Entry (Multiple)
        </a>

        <a *ngIf="hasTools && !isDhhOnly" mat-list-item [routerLink]="['/', 'my-tools']" tabindex="0" role="link" aria-label="My Tools">
          My Tools
        </a>

        <div class="bottom-buttons">
          <ng-template [ngTemplateOutlet]="autoSaveStates"></ng-template>

          <button
            mat-raised-button
            aria-label="Click to View Help Information"
            (click)="onOpenHelp()"
            tabindex="0"
            *ngIf="!isLeaUserManager && !isGeneralEducation"
          >
            Help
          </button>
          <button
            mat-raised-button
            *ngIf="achieveSettings.releaseNotesText && achieveSettings.releaseNotesUrl && !isLeaUserManager && !isGeneralEducation"
          >
            <a
              href="{{ achieveSettings.releaseNotesUrl }}"
              tabindex="0"
              role="link"
              target="_blank"
              aria-labelledby="Click to View System Release Notes/Updates"
            >
              System Release Notes/Updates
            </a>
          </button>

          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngIf="!isLeaUserManager && !isGeneralEducation">
              <mat-expansion-panel-header class="position-relative">
                <mat-panel-title class="display-flex align-items-center"> ACHIEVE Support </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <!-- Removed (but will be re-added) as per #14861 -->
                <!-- <a
              mat-list-item
              href="https://docs.google.com/document/d/1QS66xTSdOb4ChgatcAtwHEnYg8v-xCUVSCGmMhvF-ek/edit"
              target="_blank"
              tabindex="10"
              role="link"
            >
              User Guide
            </a> -->
                <a mat-list-item href="https://achievesupport.happyfox.com/new" target="_blank" tabindex="10" role="link">
                  Support Request
                </a>
                <a mat-list-item href="mailto:achievesupport@iowa.gov"> Support Email </a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-elevation-z0" *ngIf="!isLeaUserManager && !isGeneralEducation">
              <mat-expansion-panel-header class="position-relative">
                <mat-panel-title class="display-flex align-items-center"> User Guides </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <!-- Removed (but will be re-added) as per #14861 -->
                <!-- <a
                  mat-list-item
                  href="https://docs.google.com/document/d/1QS66xTSdOb4ChgatcAtwHEnYg8v-xCUVSCGmMhvF-ek/edit"
                  target="_blank"
                  tabindex="10"
                  role="link"
                >
                  User Guide
                </a> -->
                <a mat-list-item (click)="openGeneralGuide()" target="_blank" tabindex="10" role="link"> General Navigation </a>
                <a mat-list-item (click)="openEarlyAccessGuide()" target="_blank" tabindex="10" role="link"> Early ACCESS </a>
                <a mat-list-item (click)="openSpecialEducationGuide()" target="_blank" tabindex="10" role="link"> Special Education </a>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <span class="version">
          <small>v{{ version }}</small>
        </span>
      </div>

      <div class="contain-list" *ngIf="isVr">
        <a mat-list-item [routerLink]="['/', 'ivrs']" tabindex="0" role="link" aria-label="My Dashboard">My Dashboard</a>
        <a mat-list-item [routerLink]="['/', 'ivrs', 'child-search']" tabindex="0" role="link" aria-label="Child Search">Child Search</a>
        <a mat-list-item [routerLink]="['/', 'ivrs', 'location-search']" tabindex="0" role="link" aria-label="Location Search"
          >Location Search</a
        >
        <a mat-list-item [routerLink]="['/', 'ivrs', 'consent']" tabindex="0" role="link" aria-label="Upload Consent">Upload Consent</a>
        <a mat-list-item [routerLink]="['/', 'canned-report', 'reports']" tabindex="0" role="link" aria-label="Reporting">Reporting</a>
        <a
          mat-list-item
          [routerLink]="['/', 'ivrs', 'case-assignment']"
          tabindex="0"
          role="link"
          aria-label="Case Assignment"
          *ngIf="isVRManagementPhase2 && isVRManagement"
          >Case Assignment</a
        >
        <a
          mat-list-item
          [routerLink]="['/', 'ivrs', 'user-management']"
          tabindex="0"
          role="link"
          aria-label="User Management"
          *ngIf="isVRManagement"
          >User Management</a
        >

        <div class="bottom-buttons">
          <ng-template [ngTemplateOutlet]="autoSaveStates"></ng-template>
        </div>
        <span class="version">
          <small>v{{ version }}</small>
        </span>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content #sideNavContent class="content mat-typography" (scroll)="onScroll($event)">
    <mat-toolbar class="nav--top" [style.display]="isPopup ? 'none' : ''">
      <div class="app-title" #topNav>
        <ng-container>
          <button
            type="button"
            mat-icon-button
            [attr.aria-label]="drawerOpen ? 'Hide Left Navigation' : 'Show Left Navigation'"
            (click)="drawer.toggle()"
            *ngIf="!isInteractiveAccess && isHandset$ | async"
            tabindex="0"
          >
            <mat-icon *ngIf="!drawerOpen" aria-label="Show Left Navigation">keyboard_arrow_right </mat-icon>
            <mat-icon *ngIf="drawerOpen" aria-label="Hide Left Navigation">keyboard_arrow_left </mat-icon>
          </button>
          <span *ngIf="!isInteractiveAccess && isHandset$ | async" class="app-title">
            <img src="../../assets/img/achieve-logo-sm.svg" alt="ACHIEVE Logo" class="sidenav__logo pl-3" />
          </span>

          <button
            *ngIf="!isInteractiveAccess && !isMobile"
            tabindex="0"
            type="button"
            mat-icon-button
            [attr.aria-label]="drawerOpen ? 'Hide Left Navigation' : 'Show Left Navigation'"
            (click)="toggleDrawer()"
          >
            <mat-icon *ngIf="!drawerOpen" aria-label="Show Left Navigation">keyboard_arrow_right </mat-icon>
            <mat-icon *ngIf="drawerOpen" aria-label="Hide Left Navigation">keyboard_arrow_left </mat-icon>
          </button>
          <span *ngIf="!isInteractiveAccess && !isMobile && !drawerOpen" class="app-title">
            <img src="../../assets/img/achieve-logo.svg" alt="ACHIEVE Logo" class="sidenav__logo pl-3" />
          </span>
        </ng-container>
      </div>

      <p *ngIf="achieveSettings?.environment === 'uat1'" class="sandbox">SANDBOX SITE (simulated data)</p>

      <span class="fill-remaining-space"></span>

      <a
        *ngIf="achieveSettings.plsText && !isFamily && !isLearner && !isVr && !isGeneralEducation && !isInteractiveAccess"
        class="mr-3 pls"
        aria-label="Professional Learning System"
        role="link"
        (click)="openPLS()"
        href="javascript:void(0)"
        tabindex="0"
      >
        <img src="../../assets/img/achieve-logo-sm.svg" alt="ACHIEVE Logo" />
        {{ achieveSettings.plsText }}
      </a>
      <a
        class="mr-3"
        type="button"
        aria-label="View Sticky Notes"
        mat-icon-button
        (click)="onOpenStickyNotes()"
        title="View Sticky Notes"
        tabindex="0"
        role="link"
      >
        <mat-icon class="sticky-note__icon" aria-label="View Sticky Notes"> note</mat-icon>
      </a>

      <div class="user__options">
        <span class="user">{{ userFullName ? userFullName : userEmail }} </span>
        <button mat-icon-button aria-label="User Actions Menu" [matMenuTriggerFor]="menu" tabindex="0">
          <mat-icon aria-label="User Actions Menu">arrow_drop_down</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <a
            mat-menu-item
            aria-label="Team Management"
            [routerLink]="['/', 'users', 'settings']"
            tabindex="0"
            *ngIf="!isFamily && !isLearner && !isVr && !isGeneralEducation && !isLeaUserManager && !isInteractiveAccess"
          >
            Team Management
          </a>
          <ng-container *ngIf="authService.isSuperAdmin && featureFlagService.featureOn(FeatureFlags.Ivrs)">
            <button *ngIf="!isVr" mat-menu-item aria-label="Switch to IVRS" (click)="switchVersion('ivrs')" tabindex="0">
              Switch to IVRS
            </button>
            <button *ngIf="isVr" mat-menu-item aria-label="Switch to Achieve" (click)="switchVersion('achieve')" tabindex="0">
              Switch to Achieve
            </button>
          </ng-container>
          <a
            mat-menu-item
            aria-label="Settings"
            [routerLink]="['/', 'portal', 'settings']"
            tabindex="0"
            *ngIf="!isVr && (isFamily || isLearner)"
          >
            Settings
          </a>
          <button mat-menu-item aria-label="Sign Out" (click)="logout()" tabindex="0">Sign Out</button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <div class="main-content mat-horizontal-content-container" aria-live="assertive">
      <img class="img--top bg-img" draggable="false" tabindex="-1" alt="" src="../../assets/img/background/BG.svg" />

      <div class="simul-user-warning" *ngIf="!isPortalUser">
        <app-page-under-edit-header
          [protectedRoutes]="['/evaluation/', '/reevaluation/', '/learner-management/', '/cases/']"
          backgroundColor="red"
        >
          <ng-container>There are multiple users on this page.</ng-container>
        </app-page-under-edit-header>
      </div>

      <router-outlet></router-outlet>

      <ng-container *ngIf="!isPopup">
        <app-scroll-to-top [element]="topNav" [scrollPosition]="scrollPosition"></app-scroll-to-top>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #autoSaveStates>
  <div
    *ngIf="safeSavingState$ | async as savingState"
    class="autosave"
    [ngClass]="{
      'autosave--operating':
        savingState === autosavingStates.Saving || savingState === autosavingStates.Saved || savingState === autosavingStates.Failed,
      'autosave--failed': savingState === autosavingStates.Failed
    }"
  >
    <ng-container *ngIf="savingState === autosavingStates.Saving">
      <div class="autosave__icon">
        <mat-icon class="autosave__icon autosave__icon--sync" aria-labelledby="Synchronizing">sync</mat-icon>
      </div>
      <span class="autosave__text">Saving...</span>
    </ng-container>

    <ng-container *ngIf="savingState === autosavingStates.Saved || savingState === autosavingStates.Done">
      <div class="autosave__icon">
        <mat-icon class="autosave__icon" aria-label="Cloud done">cloud_done</mat-icon>
      </div>
      <span class="autosave__text" *ngIf="savingState === autosavingStates.Saved">Saved</span>
    </ng-container>
    <ng-container *ngIf="savingState === autosavingStates.Failed">
      <div class="autosave__icon">
        <mat-icon class="autosave__icon" aria-labelledby="Save Failed!">error</mat-icon>
      </div>
      <span class="autosave__text">Save Failed!</span>
    </ng-container>
  </div>
</ng-template>
