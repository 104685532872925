<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">Update Learner Location</h3>

<div mat-dialog-content class="py-3 mt-3">
  <mat-card class="my-0">
    <ng-container [formGroup]="formGroup">
      <div class="row">
        <div class="col no-form-field-padding">
          <app-location-filter
            startFrom="district"
            endWith="district"
            [isStateWide]="true"
            districtFormControlName="attendingDistrictId"
            districtLabel="Attending District"
            [showDistrictAsterisk]="true"
            [formGroup]="formGroup"
          ></app-location-filter>
        </div>
        <div class="col no-form-field-padding">
          <app-location-filter
            startFrom="district"
            endWith="building"
            [isStateWide]="true"
            districtLabel="Resident District"
            districtFormControlName="residentDistrictId"
            [showDistrictAsterisk]="true"
            [showBuildingAsterisk]="false"
            (loaded)="loadLearnerLocations()"
            [formGroup]="formGroup"
          ></app-location-filter>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel" (click)="onCancel()" class="background-color--muted mr-1" tabindex="0">Cancel</button>

  <button mat-raised-button aria-label="Submit" color="primary" (click)="onUpdate()" [disabled]="formGroup.invalid" tabindex="0">
    Update
  </button>
</div>
