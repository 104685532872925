import { NgModule } from '@angular/core';

import { DhhCalendarComponent } from './dhh-calendar/dhh-calendar.component';
import { DhhPendingAppointmentsComponent } from '../dhh-appointment/dhh-pending-appointments/dhh-pending-appointments.component';
import { CommonModule } from '@angular/common';
import { AppMatModule } from '../../../shared/app-mat.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DhhEventDetailComponent } from './dhh-event-detail/dhh-event-detail.component';
import { DhhLegendsTableComponent } from './dhh-legends-table/dhh-legends-table.component';
import { DhhScreeningRecheckFiltersComponent } from './dhh-screening-recheck-filters/dhh-screening-recheck-filters.component';
import { DhhLearnerAppointmentParticipantTableComponent } from './dhh-event-detail/dhh-learner-appointment-participant-table/dhh-learner-appointment-participant-table.component';
import { DhhCancelLearnerAppointmentComponent } from './modals/dhh-cancel-learner-appointment/dhh-cancel-learner-appointment.component';
import { DhhPendingAppointmentsTableComponent } from './dhh-pending-appointments-table/dhh-pending-appointments-table.component';
import { DhhLearnerAppointmentsDetailComponent } from './modals/dhh-learner-appointments-detail/dhh-learner-appointments-detail.component';
import {
  DhhUploadModalAboveDirective,
  DhhUploadModalContainerComponent,
  DhhUploadModalFieldsDirective,
  DhhUploadModalFileFieldDirective,
} from './dhh-upload-modal-container/dhh-upload-modal-container.component';
import { DhhLearnerReassignAudiologistComponent } from './modals/dhh-learner-reassign-audiologist/dhh-learner-reassign-audiologist.component';
import { DhhScreeningRecheckNewLearnerComponent } from './dhh-screening-recheck-new-learner/dhh-screening-recheck-new-learner.component';
import { DhhLearnerEntryFormComponent } from './dhh-learner-entry-form/dhh-learner-entry-form.component';
import { DhhLearnerScreeningFormComponent } from './modals/dhh-learner-screening-form/dhh-learner-screening-form.component';
import { DhhScreeningRecheckPassCriteriaFormComponent } from './modals/dhh-screening-recheck-pass-criteria-form/dhh-screening-recheck-pass-criteria-form.component';
import { DhhFamilyContactInfoComponent } from './dhh-family-contact-info/dhh-family-contact-info.component';
import { DhhOutputStatementsComponent } from './dhh-output-statements/dhh-output-statements.component';
import { DhhScreeningRecheckPrintRunningListComponent } from './modals/dhh-screening-recheck-print-running-list/dhh-screening-recheck-print-running-list.component';
import { DhhPrintAppointmentConfirmationLetterComponent } from './modals/dhh-print-appointment-confirmation-letter/dhh-print-appointment-confirmation-letter.component';
import { AudiogramDialogComponent } from './audiogram-components/audiogram-dialog/audiogram-dialog.component';
import { AudiogramChartComponent } from './audiogram-components/audiogram-chart/audiogram-chart.component';
import { ChartsModule } from 'ng2-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DhhAddConsentAgencyModalComponent } from './modals/dhh-add-consent-agency/dhh-add-consent-agency.component';
import { DhhLearnerGradeViewComponent } from './dhh-learner-grade-view/dhh-learner-grade-view.component';
import { UpdateLearnerLocationsComponent } from './modals/update-learner-locations/update-learner-locations.component';

@NgModule({
  declarations: [
    DhhCalendarComponent,
    DhhPendingAppointmentsComponent,
    DhhPendingAppointmentsTableComponent,
    DhhEventDetailComponent,
    DhhLegendsTableComponent,
    DhhScreeningRecheckFiltersComponent,
    DhhLearnerAppointmentParticipantTableComponent,
    DhhCancelLearnerAppointmentComponent,
    DhhLearnerAppointmentsDetailComponent,
    DhhUploadModalContainerComponent,
    DhhUploadModalFieldsDirective,
    DhhUploadModalAboveDirective,
    DhhUploadModalFileFieldDirective,
    DhhLearnerReassignAudiologistComponent,
    DhhScreeningRecheckNewLearnerComponent,
    DhhLearnerEntryFormComponent,
    DhhLearnerScreeningFormComponent,
    DhhScreeningRecheckPassCriteriaFormComponent,
    DhhFamilyContactInfoComponent,
    DhhOutputStatementsComponent,
    DhhScreeningRecheckPrintRunningListComponent,
    DhhPrintAppointmentConfirmationLetterComponent,
    AudiogramDialogComponent,
    AudiogramChartComponent,
    DhhAddConsentAgencyModalComponent,
    DhhLearnerGradeViewComponent,
    UpdateLearnerLocationsComponent,
  ],
  imports: [
    CommonModule,
    AppMatModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FullCalendarModule,
    ChartsModule,
    AngularSvgIconModule,
  ],
  exports: [
    DhhPendingAppointmentsComponent,
    DhhPendingAppointmentsTableComponent,
    DhhCalendarComponent,
    DhhEventDetailComponent,
    DhhLearnerAppointmentParticipantTableComponent,
    DhhLegendsTableComponent,
    DhhScreeningRecheckFiltersComponent,
    DhhUploadModalContainerComponent,
    DhhUploadModalFieldsDirective,
    DhhUploadModalAboveDirective,
    DhhUploadModalFileFieldDirective,
    DhhLearnerReassignAudiologistComponent,
    DhhLearnerEntryFormComponent,
    DhhLearnerScreeningFormComponent,
    DhhScreeningRecheckPassCriteriaFormComponent,
    DhhFamilyContactInfoComponent,
    DhhOutputStatementsComponent,
    DhhScreeningRecheckPrintRunningListComponent,
    DhhPrintAppointmentConfirmationLetterComponent,
    AudiogramDialogComponent,
    AudiogramChartComponent,
    AngularSvgIconModule,
    DhhAddConsentAgencyModalComponent,
    DhhLearnerGradeViewComponent,
  ],
})
export class DhhSharedModule {}
