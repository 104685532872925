<div *ngIf="formGroup">
  <ng-container [formGroup]="formGroup">
    <mat-card class="mt-4">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field label="Learner Last Name" formControlName="lastName"></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field label="First Name" formControlName="firstName"></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-date-picker label="DOB" controlName="dateOfBirth" [max]="today" [min]="twentyFiveYearsAgo"></app-date-picker>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-number-field
              label="State ID"
              formControlName="stateAssignedId"
              [shouldAllowNegatives]="false"
              [shouldAllowDecimals]="false"
            ></app-number-field>
            <mat-error
              style="display: inline"
              *ngIf="formGroup.controls.stateAssignedId.touched && formGroup.get('stateAssignedId').hasError('stateIdInvalidLength')"
            >
              <span class="text-sm">State ID must be 10 digit numbers</span>
            </mat-error>
          </div>
          <div class="col-lg-6 no-form-field-padding" *ngIf="!isLearnerUnder3">
            <app-autocomplete label="Grade" formControlName="grade" [options]="gradeOptions"></app-autocomplete>
          </div>
          <div class="col-lg-6 no-form-field-padding" *ngIf="isLearnerUnder3">
            <label>Grade: U3</label>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-autocomplete label="AEA" formControlName="attendingAeaId" [options]="aeaOptions"></app-autocomplete>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-autocomplete label="District" formControlName="attendingDistrictId" [options]="districtOptions"></app-autocomplete>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-autocomplete label="Building" formControlName="buildingId" [options]="buildingOptions"></app-autocomplete>
          </div>
          <div class="col-lg-3 no-form-field-padding">
            <app-autocomplete
              label="Teacher"
              formControlName="teacherId"
              [disabledState]="!formGroup.get('attendingDistrictId')?.value"
              [options]="teacherOptions"
            ></app-autocomplete>
          </div>
          <div class="col-lg-4 no-form-field-padding">
            <app-radio-group label="Gender" formControlName="genderId" [options]="genderOptions"></app-radio-group>
          </div>
        </div>
      </div>
    </mat-card>

    <ng-container *ngIf="!minimalInputRequirement">
      <section class="card-spacing-top">
        <h3>Family Contact Information</h3>
        <div class="row">
          <div class="col-md-12">
            <div *ngIf="isFamilyContactEditing" class="mb-3">
              <app-family-member
                [formGroup]="getFamilyFormGroup"
                [parentForm]="formGroup"
                [cardTitle]="editTitle"
                [relationshipOptions]="relationshipOptions"
                [referralHowHearAboutUsOptions]="[]"
                [howHearAboutUsOtherId]="''"
                [isFromFamilyContact]="true"
                [isPartCLearner]="false"
              >
              </app-family-member>
              <div class="action__row">
                <button mat-raised-button aria-label="Cancel Button" (click)="cancelEditFamilyMember()" tabindex="0">Cancel</button>
                <button
                  color="primary"
                  mat-raised-button
                  aria-label="Save Button"
                  [disabled]="!isFamilyFormValid"
                  (click)="saveFamilyMember()"
                  tabindex="0"
                >
                  Save
                </button>
              </div>
            </div>

            <mat-card class="py-1 position-fix--card">
              <div class="table-overflow app-table">
                <table matSort mat-table [dataSource]="dataSource" class="w-100">
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let family">
                      <div class="display-flex align-items-center justify-content-start">
                        <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="editFamilyMember(family)" tabindex="0">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button aria-label="Delete Icon" mat-icon-button color="warn" (click)="deleteFamilyMember(family)" tabindex="0">
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="fullName">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let family">{{ family.fullName }}</td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let family">{{ family.email }}</td>
                  </ng-container>

                  <ng-container matColumnDef="homePhone">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef>Phones</th>
                    <td mat-cell *matCellDef="let family" class="column-nowrap">
                      <span [style.display]="'block'" *ngIf="family.homePhone">
                        {{ family.homePhone | phone }}
                      </span>
                      <span [style.display]="'block'" *ngIf="family.workPhone">
                        {{ family.workPhone | phone }}
                      </span>
                      <span [style.display]="'block'" *ngIf="family.cellPhone">
                        {{ family.cellPhone | phone }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="familyRelationship">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef>Relationship</th>
                    <td mat-cell *matCellDef="let family">
                      {{ family.familyRelationship }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator [dataSource]="dataSource"></mat-paginator>
                <button
                  mat-icon-button
                  aria-label="Add Icon"
                  color="primary"
                  (click)="addingNewFamilyMember()"
                  *ngIf="!isFamilyContactEditing"
                  class="position-fix--add"
                  tabindex="0"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
              </div>
              <p *ngIf="!dataSource.data">No data yet...</p>
            </mat-card>
          </div>
        </div>
      </section>

      <section class="card-spacing-top">
        <mat-card>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-4 no-form-field-padding">
                <!-- <app-text-field label="Birth Hospital" formControlName="birthHospital"></app-text-field> -->
                <app-overwrite-text-field
                  label="Birth Hospital"
                  formControlName="birthHospital"
                  [sourceData]="learner"
                  [isBankableField]="true"
                  [bankableFieldId]="bankableFieldIds.dhhHealthInfo.birthHospital"
                  [bankableFieldChildFname]="learner?.firstName"
                ></app-overwrite-text-field>
              </div>
              <div class="col-lg-4 no-form-field-padding">
                <app-radio-group
                  label="Was your child in the NICU?"
                  formControlName="hadNICUCare"
                  [options]="yesNo"
                  [inline]="true"
                ></app-radio-group>
                <ng-container *ngIf="formGroup.get('hadNICUCare').value">
                  <app-number-field [min]="0" label="If Yes, how many days?" formControlName="numberOfDaysInNICU"></app-number-field>
                </ng-container>
              </div>
              <div class="col-lg-4 no-form-field-padding">
                <app-radio-group
                  label="Is there a family history of hearing loss?"
                  formControlName="isThereHearingLossHistory"
                  [options]="yesNo"
                  [inline]="true"
                ></app-radio-group>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-12 no-form-field-padding">
                <app-checkbox
                  [options]="riskFactorOptions"
                  controlName="riskFactors"
                  label="EDHI risk factors"
                  [wrap]="true"
                ></app-checkbox>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-12 no-form-field-padding">
                <app-textarea
                  label="Other Notes"
                  formControlName="otherNotes"
                  [allowTextOverflow]="true"
                  [autoSize]="true"
                  [rows]="2"
                ></app-textarea>
              </div>
            </div>
          </div>
        </mat-card>
      </section>
    </ng-container>

    <div class="action__row">
      <button mat-raised-button aria-label="Cancel Button" (click)="onClose()" tabindex="0">Cancel</button>
      <button
        *ngIf="showSaveAndCloseButton"
        mat-raised-button
        aria-label="Save and Close Button"
        color="primary"
        (click)="onSubmit()"
        [disabled]="(getFamilyFormGroup.dirty && isFamilyContactEditing) || !isBasicLearnerInfoProvided"
        tabindex="0"
      >
        Save and Close
      </button>

      <button
        color="primary"
        mat-raised-button
        aria-label="Complete"
        [disabled]="!isFormValid || isFamilyContactEditing"
        (click)="onSubmit()"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </ng-container>
</div>
